export const None: unique symbol = Symbol()
export type NoneType = typeof None
export type Option<T> = T | NoneType

export type Result<ResT> = ResT | Error
export function isError<T>(res: T | Error): res is Error {
  return res instanceof Error
}
export function unwrap<T>(res: Result<T>): T {
  if (isError(res)) {
    throw res
  }
  return res
}

export class FirestoreStored<T> {
  readonly value: T
  readonly path: string
  readonly id: string

  constructor(value: T, path: string) {
    this.value = value
    this.path = path

    const id = this.path.split('/').pop()
    if (id === undefined) {
      throw new Error(`Provided path is invalid ${path}`)
    }
    this.id = id
  }
}

export enum SubscriptionStatus {
  /** Status is not known */
  Unknown = 'unknown',

  /** Subscription is new and may not yet be active */
  New = 'new',

  /** Subscription is active */
  Active = 'active',
  /** Subscription is transitioning from active to RenewalCancelled */
  ActivePendingCancellation = 'active_pending_cancellation',

  /** Projects are actively serving but will not renew */
  RenewalCancelled = 'renewal_cancelled',
  /** Projects are not serving and account will never be billed */
  Inactive = 'inactive',
}

export enum SubscriptionAction {
  Start = 'start',
  Stop = 'stop',
}

export type Account = {
  contact: {
    primary_email: string
  }
  projects?: {
    [key: string]: unknown
  }
  stripe: {
    customer_id: string
  }
  subscription: {
    status: SubscriptionStatus
  }
  name: string
  users: {
    role: 'owner'
    uid: string
  }[]
}

export type UserAuthConfig = {
  accounts?: {
    [key: string]: {
      owns: boolean
    }
  }
  projects?: {
    [key: string]: {
      sync: boolean
      update_source: boolean
      configure_domain: boolean
      change_payment: boolean
      view: boolean
    }
  }
}

export type PatronAgreements = {
  agreed_to_terms_of_service?: boolean
  agreed_to_privacy_policy?: boolean
}

// TODO(alex): s/user/patron/ everywhere
export type Patron = {
  accounts?: string[]
  auth_config?: UserAuthConfig
  contact: {
    primary_email: string
  }
  // TODO(alex): s/user_status/agreements/
  user_status?: PatronAgreements
}

/** The status of a given domain->project association */
export enum DomainAssociationStatus {
  /** The domain doesn't have any associated projects */
  unassociated = 'unassociated',
  /** The domain association is pending verification */
  pending = 'pending',
  // TODO(alex): add a `verified` state between pending and associated
  /** The domain association has been verified */
  associated = 'associated',
  /** The domain is already associated with a different project */
  collision = 'collision',
  /** The domain verification token has expired */
  expired = 'expired',
  /** The domain has transfered away from this (previously associated) project */
  transferred = 'transferred',
}

/**
 * An association between a domain and a project
 *
 * Multiple aliases may be proposed at the same time.  When some proposal
 * resolves to "associated", all other proposals will be resolved to
 * "collision".  New proposals may be added even after a project is successfully
 * associated.  Existing proposals may also be updated to "pending".
 *
 * Proposals are stored in the $alias/proposals collection.
 *
 * Some Example Status Flows:
 * * Happy path
 *   1) created
 *   2) verified (through, say, TXT record)
 *   3) marked as "associated"
 * * Expiration
 *   1) created
 *   2) last_status_update_time passes out of the reasonable validation window
 *   3) marked as "expired"
 * * Collision
 *   1) created proposal for project *foo* (pending)
 *   2) created proposal for project *bar* (pending)
 *   3) verified project *foo*
 *   4) project *foo*'s proposal marked as "associated"
 *   5) project *bar*'s proposal (and any other non-expired proposals) marked
 *      as "collision"
 * * Ownership transfer
 *   1) associated project *foo*
 *   2) created proposal for project *bar* (pending)
 *   3) verified project *bar*
 *   4) project *bar*'s proposal marked as "associated"
 *   5) project *foo*'s proposal marked as "transferred"
 */
export type DomainAlias = {
  /**
   * NOTE: domain string must match firestore ID. This duplication simplifies
   * passing around DomainAlias vs. FirestoreStored<DomainAlias> and removes the
   * oddity of extracting canonical domain information from the firestore ID vs
   * a properly named field.
   */
  domain: string
  status: DomainAssociationStatus
  last_status_update_time: Date

  /**
   * The path to the project associated with this alias
   *
   * This string is a project reference path of the form "projects/<PROJECT_ID>"
   */
  project?: string
}

export type DomainAliasStatus = {
  creation_time: Date
  last_update_time: Date
  status: DomainAssociationStatus
}

export type Source = {
  project: string
  type: SourceType
  source_url: string
  content_subfolder_path: string

  deploy_credential?: string

  // In Firestore, credentials are stored as a sub-collection.  This field
  // allows inclusion of credentials for various API requests which manipulate
  // them and makes the RESTful object model a tad more consistent.
  credentials?: Record<string, SourceCredential>
}

export enum SourceCredentialType {
  ssh_deploy_key = 'ssh_deploy_key',
  dropbox_refresh_token = 'dropbox_refresh_token',
}
export type SourceCredential = {
  type: SourceCredentialType
  [SourceCredentialType.ssh_deploy_key]?: {
    public_key_string: string
    private_key_id: string
  }
  [SourceCredentialType.dropbox_refresh_token]?: {
    token_struct_id: string
  }
}

export enum SourceSnapshotStatusType {
  Pending = 'Pending',
  Successful = 'Successful',
  Failed = 'Failed',
}

export type SourceSnapshotStatus = {
  type: SourceSnapshotStatusType
}

export type SourceVersion = {
  gcs_sub_path: string
  sync_status?: SourceSnapshotStatus
}

export type Project = {
  name: string
  // TODO(alex): Resolve inconsistency where we type this as a string here but
  // it's commonly a DocumentReference in Firestore.
  primary_source: string
  // TODO(alex): Resolve inconsistency where we type this as a string here but
  // it's commonly a list of DocumentReferences in Firestore.
  sources: string[]

  // Mapping from domain to DomainAliasStatus
  domain_aliases?: Record<string, DomainAliasStatus>
  pinned_domain_alias?: string

  dropbox?: {
    authz_flow?: {
      pkce_code_verifier: string
      time_started: FirebaseFirestore.Timestamp
    }
  }
}

export enum SourceType {
  // Supported
  dropbox = 'dropbox',
  public_git_http = 'public_git_http',
  private_git_ssh = 'private_git_ssh',

  // Not yet supported
  google_drive = 'google_drive',
  icloud_storage = 'icloud_storage',
  microsoft_onedrive = 'microsoft_onedrive',
}

export function sourceTypeFromUrlProtocol(
  protocol: string,
): Result<SourceType> {
  switch (protocol) {
    case 'https':
    // FALLTHROUGH INTENDED
    case 'http': {
      return SourceType.public_git_http
    }
    case 'ssh': {
      return SourceType.private_git_ssh
    }
    case 'dropbox': {
      return SourceType.dropbox
    }
    default: {
      return new Error(`Source URL protocol of "${protocol}" is unrecognized`)
    }
  }
}

export function urlProtocolForSourceType(type: SourceType): Result<string> {
  switch (type) {
    case SourceType.public_git_http: {
      return 'https'
    }
    case SourceType.private_git_ssh: {
      return 'ssh'
    }
    case SourceType.dropbox: {
      return ''
    }
    default: {
      return new Error(
        `Source type of "${type}" does not have a known protocol`,
      )
    }
  }
}

export type StripeEventRecord = {
  /** `JSON.stringify(FirebaseFirestore.Timestamp)` */
  received_datetime: FirebaseFirestore.Timestamp
  type: string
  data_object: unknown
}
