import React, { FunctionComponent } from 'react'
import { WindowLocation } from '@reach/router'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

// import { navigate } from 'gatsby'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import * as api from '../utils/api'

export const MESSAGE_SOURCE = 'SourceManagerDropboxAuthReceiver'
export type AdminPaneMessage = {
  source: string
  payload: {
    status: string
    sourceId: string
  }
}

function sendMessageToAdminPane(payload: AdminPaneMessage['payload']) {
  const opener = window.opener as Window | undefined
  if (!opener) {
    // TODO(alex): Communicate this failure to the user
    console.error('window.opener was null')
    return
  }

  const message: AdminPaneMessage = {
    source: MESSAGE_SOURCE,
    payload,
  }

  opener.postMessage(message, '*')
}

type DropboxAuthReceiverProps = {
  firebase: firebase.FirebaseApp
  location: WindowLocation
}

const DropboxAuthReceiverController = ({
  firebase: firebaseApp,
}: DropboxAuthReceiverProps) => {
  const [dropboxAuthFlowCode, setDropboxAuthFlowCode] = React.useState<
    undefined | string
  >(undefined)

  React.useEffect(() => {
    if (dropboxAuthFlowCode === undefined) {
      const codeParam = new URLSearchParams(location.search).get('code')
      if (codeParam) {
        setDropboxAuthFlowCode(codeParam)
      }
    }
  }, [dropboxAuthFlowCode])

  React.useEffect(() => {
    let isCanceled = false

    const asyncEffect = async () => {
      if (isCanceled) {
        return
      }

      if (dropboxAuthFlowCode !== undefined) {
        console.log('Completing Dropbox authorization / linkage flow')

        let sourceId: string | undefined = undefined
        try {
          sourceId = await api.completeDropboxAuthorizationFlow(
            firebaseApp,
            dropboxAuthFlowCode,
          )
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : JSON.stringify(error)
          // TODO(alex): Notify user and appropriately close window/tab
          console.error(
            'An error occurred in trying to complete the Dropbox authorization flow: ' +
              errorMessage,
          )
          return
        }

        sendMessageToAdminPane({
          sourceId,
          status: 'success',
        })

        // TODO(alex): close window/tab and complete flow with initial
        // SourceManager link action.
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }

      void asyncEffect()
    })

    return () => {
      unsubscribe()
      isCanceled = true
    }
  }, [firebaseApp, dropboxAuthFlowCode])

  return {}
}

const DropboxAuthReceiver: FunctionComponent<DropboxAuthReceiverProps> = (
  props,
) => {
  const c = DropboxAuthReceiverController(props)
  void c

  return (
    <>
      <Helmet
        title={'Linking Dropbox to HostBurro'}
        meta={[
          {
            name: 'description',
            content: 'Finish linking Dropbox account to HostBurro',
          },
          {
            name: 'keywords',
            content: 'website, hosting, storage, dashboard',
          },
        ]}
      />
      <Grid container justifyContent="center" spacing={2}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={'auto'}>
            <Typography>
              Finalizing linkage between Dropbox and HostBurro
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={'auto'}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DropboxAuthReceiver
