import firebase from 'firebase/app'

import React, { FunctionComponent } from 'react'

import { Router, WindowLocation } from '@reach/router'

import DefaultLayout from '../layouts/index'

import SourceManagerDropboxAuthReceiver from '../components/source-manager-dropbox-auth-receiver'
import FirebaseLoadable from '../components/firebase-loadable'
import PrivateRoute from '../components/private-route'

type DropboxAuthReceiverPageProps = {
  location: WindowLocation
}

const DropboxAuthReceiverPage: FunctionComponent<DropboxAuthReceiverPageProps> =
  ({ location }: DropboxAuthReceiverPageProps) => {
    let resolver
    let rejecter
    const firebasePromise = new Promise<firebase.FirebaseApp>(
      (resolve, reject) => {
        resolver = resolve
        rejecter = reject
      },
    )
    const [firebaseApp, setFirebaseApp] = React.useState<
      firebase.FirebaseApp | undefined
    >(undefined)

    firebasePromise
      .then((fbApp: firebase.FirebaseApp) => {
        setFirebaseApp(fbApp)
      })
      .catch((reason) => {
        console.error(reason)
      })

    return (
      <DefaultLayout location={location} firebase={firebaseApp}>
        <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
        {!firebaseApp ? (
          <div>Initializing Firebase...</div>
        ) : (
          <Router basepath="/">
            <PrivateRoute
              location={location}
              default
              firebase={firebaseApp}
              component={SourceManagerDropboxAuthReceiver}
            />
          </Router>
        )}
      </DefaultLayout>
    )
  }

export default DropboxAuthReceiverPage
