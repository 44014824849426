export {
  DomainAlias,
  DomainAssociationStatus,
  FirestoreStored,
  None,
  NoneType,
  Option,
  Project,
  Patron,
  PatronAgreements,
  Result,
  Source,
  SourceType,
  SubscriptionStatus,
  isError,
  sourceTypeFromUrlProtocol,
  urlProtocolForSourceType,
} from '../../../common/types'

export { SynchronizationRequestBody } from '../../../common/api'

export const CHANGE_DOMAIN_ALIAS_EVENT_TYPE = 'change-domain-alias'
export const CHANGE_DOMAIN_ALIAS_MANAGER_STATE_EVENT_TYPE =
  'change-domain-alias-manager-state'
export const SWITCH_SOURCE_EVENT_TYPE = 'switch-source'
export const CHANGE_SOURCE_EVENT_TYPE = 'change-source'
export const CHANGE_SOURCE_MANAGER_STATE_EVENT_TYPE =
  'change-source-manager-state'

export enum CredentialType {
  ssh_deploy_key = 'ssh_deploy_key',
}
export type Credential = {
  [CredentialType.ssh_deploy_key]: {
    private_key_id: string
    public_key_string: string
  }
}

export type EnvConfig = {
  environment: string
  firebaseConfig: {
    apiKey: string
    appId: string
    authDomain: string
    projectId: string
  }
  stripeConfig: {
    apiKey: string
  }
  signInDeepLink: string
  projectServingHost: string
  serverUri: string
}

export type SiteConfig = {
  siteMetadata: {
    config: EnvConfig
  }
}
