// Note: this file isn't actually TypeScript yet... it's just masquerading as
// TypeScript but is loaded as JavaScript
// TODO(alex): Actually load and compile this file as TypeScript

module.exports = {
  siteMetadata: {
    title: 'HostBurro',
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/assets/images/favicon.png',
      },
    },
    'gatsby-plugin-react-helmet',
    // Add typescript stack into webpack
    'gatsby-plugin-typescript',
    {
      resolve: 'gatsby-plugin-create-client-paths',
      options: { prefixes: ['/admin/*', '/sign-up/*', '/billing/*', '/dropbox-auth-receiver/*'] },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'terms-and-conditions',
        path: `${__dirname}/src/terms`,
      },
    },
    'gatsby-transformer-remark',
  ],
  flags: {
    DEV_SSR: false
  },
}

const environmentConfiguration = {
    local: {
      environment: 'local',
      firebaseConfig: {
        // Note: API key for stage.hostburro.com is used here.  This is
        // necessary for auth to work as there isn't a locally emulated auth
        // solution.
        apiKey: 'AIzaSyABQPjUltuKARbGWhFHpPaLlX8A_pEZcys',
        // Staging App ID.
        appId: '1:576144660076:web:f5e555db312dd2f6116a49',
        authDomain: 'stage.hostburro.com',
        projectId: 'hostburro-staging',
      },
      stripeConfig: {
        apiKey: 'pk_test_51HKrQ6JsybdhVFDlxAk0FTEbRqBEkRHDKGhsTk2L4yDvTJgfUQjY5rG1KxDKw52OTBxbPWTT3DvhCLBZcpkwGxoW00GnqMhHNI',
      },
      // TODO(alex): Set up some "local" serving system for testing App Engine
      // serving of projects through some local GCS emulator of some kind.
      signInDeepLink: 'https://stage.hostburro.com/link/sign-in',
      projectServingHost: 'stage.hburro.com',
      serverUri: 'http://localhost:9099/',
    },
    staging: {
      environment: 'staging',
      stripeConfig: {
        apiKey: 'pk_test_51HKrQ6JsybdhVFDlxAk0FTEbRqBEkRHDKGhsTk2L4yDvTJgfUQjY5rG1KxDKw52OTBxbPWTT3DvhCLBZcpkwGxoW00GnqMhHNI',
      },
      firebaseConfig: {
        apiKey: 'AIzaSyABQPjUltuKARbGWhFHpPaLlX8A_pEZcys',
        appId: '1:576144660076:web:f5e555db312dd2f6116a49',
        authDomain: 'stage.hostburro.com',
        projectId: 'hostburro-staging',
      },
      signInDeepLink: 'https://stage.hostburro.com/link/sign-in',
      projectServingHost: 'stage.hburro.com',
      serverUri: 'https://stage.hostburro.com/',
    },
    production: {
      environment: 'production',
      firebaseConfig: {
        apiKey: 'AIzaSyCie8VMdYM8guwIxIXqBHlXr-LdeYeLzko',
        appId: '1:885897753061:web:ca8e016af9dd5445ff3f0c',
        authDomain: 'hostburro.com',
        projectId: 'hostburro',
      },
      stripeConfig: {
        apiKey: 'pk_live_51HKrQ6JsybdhVFDlMScu4VEGLCu8GhV0b39A6Ti5mByjUDjYp8ehWOFUX3aq6C3C2LNDHhLVlDtJkpuyBS8wxPkJ00aWkbuK9q',
      },
      signInDeepLink: 'https://hostburro.com/link/sign-in',
      projectServingHost: 'hburro.com',
      serverUri: 'https://hostburro.com/',
    },
}
const activeEnvironment = process.env.GATSBY_ACTIVE_ENVIRONMENT || 'local';
module.exports.siteMetadata.config = environmentConfiguration[activeEnvironment];

/*
exports.onCreateWebpackConfig = ({ actions, stage }) => {
  // If production JavaScript and CSS build
  if (stage === 'build-javascript') {
    // Turn off source maps
    actions.setWebpackConfig({
      devtool: false,
    })
  }
};
 */
